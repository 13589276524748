/**
 * = Counters
 */

.counter-alternate {
  &::after {
    content: "\f067";
    font-family: $font-awesome-5;
    position: absolute;
    font-size: 2.25rem;
    color: $dark;
    font-weight: 900;
  }
}
