.menuItem {
  position: relative;
}

.menuItem:hover .menuItemInner {
  background-color: #d5e5fe !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.menuSubItems {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 200px;
}
.menuItem:hover .menuSubItems {
  display: block;
  animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
  from {
    opacity: 0.7;
    transform: translateX(-10%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
.navItemCollapse .navItem {
  margin-bottom: 0 !important;
  border: 0;
}
.navItemCollapse .navItem > a {
  border-radius: 0;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
}
.navItemCollapse .navItem > a:hover {
  background-color: #f1f5f9 !important;
}
