/**
 * = Progress bars
 */

.progress-wrapper {
  position: relative;
}

.progress-bar {
  box-shadow: none;
  border-radius: 0;
  height: auto;
}

.progress {
  height: 4px;
  margin-bottom: $spacer;
  overflow: hidden;
  border-radius: $border-radius-sm;
  background-color: $progress-bg;
  font-size: $font-size-xs;
  font-weight: $font-weight-bold;
  @include box-shadow(inset 0 1px 2px rgba(0, 0, 0, 0.1));
}

.progress-xl {
  height: $progress-xl-size;
}
.progress-lg {
  height: $progress-lg-size;
}
.progress-sm {
  height: $progress-sm-size;
}

.progress-info {
  @include display-flex();
  @include align-items(center);
  @include justify-content(space-between);
  margin-bottom: 0.5rem;

  .progress-label {
    span {
      display: inline-block;
      color: $primary;
      font-size: $font-size-sm;
      font-weight: $font-weight-bold;
    }
  }

  .progress-percentage {
    text-align: right;
    span {
      display: inline-block;
      color: $gray-700;
      font-size: $font-size-xs;
      font-weight: $font-weight-bold;
    }
  }
}

.info-xl {
  .progress-label,
  .progress-percentage {
    span {
      font-size: $font-size-sm;
    }
  }

  .progress-percentage {
    text-align: right;
  }
}

.progress-tooltip {
  background: $primary;
  color: $white;
  font-weight: $font-weight-bold;
  padding: 0.25rem 0.375rem;
  line-height: 1;
  font-size: $font-size-xs;
  position: relative;
  border-radius: $border-radius-sm;

  &:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 10px;
    border: solid transparent;
    height: 0;
    width: 0;
    pointer-events: none;
    border-top-color: $gray;
    border-width: 6px;
  }
}

@-webkit-keyframes animate-positive {
  0% {
    width: 0%;
  }
}
@keyframes animate-positive {
  0% {
    width: 0%;
  }
}

//Tooltip arrow color
@each $color, $value in $theme-colors {
  .progress-info {
    .bg-#{$color} {
      &::after {
        border-top-color: $value;
      }
    }
  }
}
