/* .content {
  padding: 0;
} */
.wrap-sidebar {
  position: relative;
}
.design-detail {
  position: absolute;
  top: 0;
  width: auto;
  animation-name: hiddendesign;
  animation-duration: 0.2s;
  visibility: hidden;
}
.design-detail.active {
  position: relative;
  animation-name: showdesign;
  animation-duration: 0.2s;
  visibility: visible;
}
@keyframes showdesign {
  from {
    opacity: 0;
    right: -100%;
  }
  to {
    opacity: 1;
    right: 0;
  }
}
@keyframes hiddendesign {
  from {
    opacity: 1;
    right: 0;
  }
  to {
    opacity: 0;
    right: -100%;
  }
}
/* .product-sidebar {
  position: absolute;
  top: 0;
  width: auto;
  visibility: hidden;
  opacity: 0;
  animation-name: hiddensidebar;
  animation-duration: 1s;
} */
@keyframes hiddensidebar {
  from {
    opacity: 1;
    right: 0;
  }
  to {
    right: -100%;
    opacity: 0;
  }
}
/* .product-sidebar.active {
  position: relative;
  opacity: 1;
  visibility: visible;
  animation-name: showsidebar;
  animation-duration: 1s;
} */
@keyframes showsidebar {
  from {
    opacity: 0;
    right: -100%;
  }
  to {
    opacity: 1;
    right: 0;
  }
}

/*design page*/
.circle-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
}
.text-color {
  display: table-caption;
}
.download-design {
  position: absolute;
  top: 0;
  right: 0;
}

/*product page*/
span.wrap-product-thumbnail {
  width: 50px;
  height: 50px;
  position: relative;
  top: 0;
  left: 0;
  display: inline-block;
  margin: 0;
  border: 1px solid #c9c9c9;
  border-radius: 5px;
}
span.wrap-product-thumbnail img.product-thumbnail {
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
img.product-thumbnail {
  max-width: 50px;
  max-height: 50px;
}
.modal-fullscreen {
  width: 100% !important;
}
.product-upload-file {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wrap-thumbnail-design.created-design .thumbnail-design {
  border: 1px dashed #6e6e6e;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 0.25rem;
}
.view-side-btn-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.view-side-btn {
  display: none;
}

.wrap-thumbnail-design:hover .view-side-btn-wrap {
  background: rgba(255, 255, 255, 0.5);
}

.wrap-thumbnail-design:hover .view-side-btn {
  display: block;
}
.wrap-thumbnail-design.created-design .thumbnail-design .created {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}
.product-form-action {
  position: fixed;
  bottom: 10px;
  z-index: 1000;
  width: calc(100% - 260px - 2rem);
}
.nbdesigner-area-design {
  border: 1px dashed #000;
  cursor: move;
  position: absolute !important;
  box-sizing: border-box;
}
.nbdesigner-area-design.selected {
  background: rgba(0, 0, 0, 0.3);
}
.nbdesigner-area-design .ui-resizable-handle {
  background-color: #fff;
  border: 2px solid #428bca;
  height: 5px;
  width: 5px;
}
.nbdesigner-area-design .ui-resizable-handle.ui-resizable-se {
  bottom: -6px;
  right: -6px;
}

/*Navbar action*/
@media only screen and (max-width: 768px) {
  .product-form-action {
    bottom: 0;
    top: auto;
  }
  @keyframes showAction {
    from {
      bottom: -100%;
    }
    to {
      bottom: 0;
    }
  }
}

@media only screen and (min-width: 768px) {
  /* Css scroll bar */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

/*style media list*/
.printcart-media-content .tab-content {
  position: absolute;
  top: 89px;
  left: 16px;
  right: 16px;
  bottom: 0px;
}
.printcart-media-content .tab-content #tab-media-tabpane-list {
  height: 100%;
}
.printcart-media-content .tab-content #tab-media-tabpane-list .media-items {
  overflow-y: auto;
  position: relative;
}
.printcart-media-content
  .tab-content
  #tab-media-tabpane-list
  .media-items
  .wrap-thumbnail-media {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;
  border: 0.0625rem solid #f1f5f9;
  border-radius: 5px;
}
.printcart-media-content
  .tab-content
  #tab-media-tabpane-list
  .media-items
  .wrap-thumbnail-media
  .thumbnail-media {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: auto;
}

.printcart-media-content .nav-tabs .nav-link {
  border: 0;
  padding: 0.75rem 1rem;
}
.printcart-media-content .tab-content #tab-media-tabpane-upload {
  height: 100%;
}
.printcart-media-content .tab-content #tab-media-tabpane-upload .dropzone {
  height: 100%;
  position: relative;
}
.printcart-media-content
  .tab-content
  #tab-media-tabpane-upload
  .dropzone
  .area-upload {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.wrap-thumbnail-media.active {
  border: 1px #64a9ff solid !important;
}
.printcart-media-content .tab-content #tab-media-tabpane-list .media-item {
  position: relative;
}
.printcart-media-content
  .tab-content
  #tab-media-tabpane-list
  .media-item
  .icon-active {
  position: absolute;
  top: 5px;
  right: 5px;
}

/*Page design*/
.wrap-thumbnail-design {
  position: relative;
}
.wrap-thumbnail-design .thumbnail-design {
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  border-radius: 0.25rem;
  overflow: hidden;
}
.wrap-thumbnail-design .thumbnail-design img {
  position: absolute;
  border-radius: 0.25rem;
  top: 50%;
  transform: translateY(-50%);
}
.download-design {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 5px;
}
.download-design span.icon {
  cursor: pointer;
  background: #fff;
  padding: 8px;
}
.background-type .sketch-picker {
  position: absolute;
}
/* .tool-resize {
  position: relative;
  display: inline-block;
  margin-top: 0.25rem;
} */
.vi-tooltip-top .tooltip-inner {
  background-color: #ff0000;
}
.vi-tooltip-top .tooltip-arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #ff0000;
}
.vi-tooltip.vi-tooltip-top {
  top: -5px;
  left: calc(50% - 31px);
  transform: translate(-50%, -100%);
  position: absolute;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 1;
}
.tool-resize .vi-tooltip .tooltip-arrow {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.dropdown-menu {
  border-color: #cbd5e1;
}

.dropdown-menu.dropdown-menu-end {
  right: 0 !important;
}

.main-nav {
  margin-left: -1rem;
  margin-right: -1rem;
}

.form-check-input {
  background-color: transparent;
  border: 1px solid #9ca3af;
}
.badge {
  font-weight: 500;
}
/* .badge.bg-primary {
  background-color: #dbeafe !important;
  color: #1e40af !important;
} */

/* .badge.bg-success {
  background-color: #d1fae5 !important;
  color: #065f46 !important;
} */

/* .badge.bg-warning {
  background-color: #fef3c7 !important;
  color: #92400e !important;
} */

.badge.bg-secondary {
  background-color: #a0a0a0 !important;
}

/* .badge.bg-danger {
  background-color: #fee2e2 !important;
  color: #991b1b !important;
} */

.table > tbody > tr > td:last-child {
  text-align: right;
}

.table > thead > tr > th:last-child {
  text-align: right;
}

.table thead th {
  font-weight: 600;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #e5e7eb;
}

.fs-7 {
  font-size: 0.875rem;
}

button.outline-info:hover {
  color: #fff;
  background-color: #0d6efd;
}
.design-area-size h6.border-bottom > svg,
.product-size h6.border-bottom > svg,
.relateive-position h6.border-bottom > svg {
  margin-left: 4px;
  cursor: pointer;
}
.__react_component_tooltip::before {
  border-right: 8px solid #c3c4c752 !important;
}
.__react_component_tooltip {
  pointer-events: inherit !important;
  border: 1px solid #c3c4c7;
  box-shadow: 0 3px 6px rgb(0 0 0 / 20%);
  padding: 0 !important;
}
.__react_component_tooltip.show {
  opacity: 1 !important;
}
.__react_component_tooltip .pointer-content > h3 {
  margin: -1px -1px 5px;
  padding: 15px 18px 14px 18px;
  border: 1px solid #2271b1;
  border-bottom: none;
  line-height: 1.4;
  font-size: 14px;
  color: #fff;
  background: #2271b1;
}
.__react_component_tooltip .pointer-content > p {
  padding: 0 20px;
  font-size: 13px;
  line-height: 1.5;
  margin: 1em 0;
}
.notice-design-area {
  font-weight: bold;
  background: #dddacd;
}
.notice-relative-position {
  font-weight: bold;
  background: #f0c6f6;
}
.notice-product-size {
  font-weight: bold;
  background: #b8dce8;
}
a.see-detail-design {
  margin: 10px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: #000;
  text-decoration: none;
}
a.see-detail-design:hover {
  color: #f00;
}
/* .line-custom {
  position: relative;
  width: 100%;
  height: 0;
  padding: 0 8px;
}
.line-custom .line {
  border: 1px dotted #000;
  width: 100%;
} */
/* .line-custom .icon-left-line {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
} */
/* .line-custom .icon-right-line {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
} */
/* .line-custom .modal-measure-size label {
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 400;
} */
/* .line-custom .modal-measure-size {
  position: absolute;
  top: 50%;
  margin-top: 10px;
  right: 0;
  padding: 10px;
  border-radius: 5px;
  background: #fff;
  width: 200px;
} */
/* .measure-size {
  position: absolute !important;
  box-sizing: border-box;
  top: 50%;
  z-index: 6;
} */
#validate-image-tooltip .tooltip-inner {
  background-color: #ff0000;
}

/*CreateTemplate*/

.close-iframe-design {
  position: fixed;
}
.form-check-input {
  width: 1rem;
  height: 1rem;
}
.nav-item.active .nav-link {
  color: #1e293b;
  background-color: #f1f5f9;
}

.border-none {
  border: 0 !important;
}

.rounded-4 {
  border-radius: 0.4rem !important;
}

.rounded-5 {
  border-radius: 0.6rem !important;
}

.rounded-top-5 {
  border-top-left-radius: 0.6rem !important;
  border-top-right-radius: 0.6rem !important;
}

.bg-blue-200 {
  background-color: #bfdbfe;
}
.bg-green-200 {
  background-color: #a7f3d0;
}
.bg-red-200 {
  background-color: #fecaca;
}

.toast-header-custom {
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
  background-clip: unset;
}

.custom-tooltip .tooltip-arrow:before {
  border-bottom-color: #fff !important;
}

.custom-tooltip .tooltip-inner {
  background-color: #fff;
  color: #0f172a;
}

.sidebar-inner .accordion-button.empty-child::after {
  display: none !important;
}

/* .dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
} */
