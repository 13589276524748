iframe {
  border: 0;
}

figcaption,
figure,
main {
  display: block;
  margin: 0;
}

main {
  overflow: hidden;
}

img {
  max-width: 100%;
}

strong {
  font-weight: $font-weight-bold;
}

button:focus {
  outline: 0;
}

label {
  font-weight: $font-weight-bold;
}

.form-switch label, .form-floating label {
  font-weight: $font-weight-normal;
}

.accordion-button, .accordion-body {
  padding: 1rem;
}
